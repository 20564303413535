import React, { useState, useCallback, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  ButtonGroup,
  Button,
  Container,
  Row,
  Col
} from "shards-react";
import ButtonClose from '../buttonClose';

import { InputSearch } from '../../unform';
import { FIELDS, LABELS } from '../../../utils/constants';
import { Local, KEYS } from '../../../services/Storage/storage.service';
import { WeightsService } from '../../../services/WebliniaERPAPI';
import { useAuth } from '../../../hooks/Auth';

const ListWeights = ( { open, toggle, modalHeader, modalBody, modalFooter, selectCallback, ...rest } ) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataset, setDataset] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [itemSelected, setItemSelected] = useState();
  const { enterprise } = useAuth();

  const getData = useCallback(async (offset, limit, params=[]) => {
    setIsLoading(true);
    setDataset([]);
    setPagination([]);

    const weightsService = new WeightsService();

    try {
      let urlParams = [
        {
          key: 'tte->id_empreendimento',
          value: enterprise.id_empreendimento
        },
      ];

      urlParams = [...urlParams, ...params];

      let { data } = await weightsService.getWeights(offset, limit, urlParams);
      setIsLoading(false);
      setDataset(data.tamanhos);
      setPagination(data.paginacao);

    } catch ({ response }) {
      setIsLoading(false);
      setDataset(null);
      setPagination(null);
    }
  }, []);

  const select = useCallback((item) => {
    if(!isSelected(item)){
      setItemSelected(item);
      selectCallback('ListWeights', item);
      toggle();
    }
  }, []);

  const isSelected = useCallback((item) => {
    if(itemSelected)
      return (item.id == itemSelected.id);
    else
      return false;
  }, [itemSelected]);

  const handleSubmitSearch = useCallback((value) => {
    let urlParams = [];
    if(value){
      urlParams = [
        {
          key: '(tt->nome_tamanho[exp]',
          value: encodeURI(`LIKE '%${value}%' OR tt.id LIKE '%${value}%')`),
        }
      ];
    }

    getData(0, 10, urlParams)
  }, []);

  useEffect(() => {
    if(open)
      getData(0, 10);
  }, []);

  return (
    <>
      <Modal open={open} toggle={toggle} {...rest}>
      <ModalHeader {...modalHeader} style={{ padding: '10px 20px'}}>
          <i className="fa fa-bars text-dark" style={{ marginRight: '5px'}}></i> Vendedores
          <ButtonClose onClick={toggle}/>
        </ModalHeader>
        <ModalHeader {...modalHeader}>Tamanhos</ModalHeader>
        <ModalBody {...modalBody} className="pt-0 pb-0 pl-2 pr-2">
          <Container fluid className='file-manager__filters border-bottom'>
            <Row>
              <Col sm='12'>
                <InputSearch submit={handleSubmitSearch} />
              </Col>
            </Row>
            <Row>
              <Col sm='12'>
                <table className="table table-sm mt-2 mb-0">
                  <thead className="bg-light">
                  {
                    (!isLoading && dataset && dataset.length > 0) && (
                      <tr>
                        <th scope="col" style={{ width: '80px' }}>{FIELDS.WEIGHTS.ID.label}</th>
                        <th scope="col">{FIELDS.WEIGHTS.NAME.label}</th>
                        <th scope="col" className="text-center" style={{ width: '110px' }}>{LABELS.ACTIONS.label}</th>
                      </tr>
                    )
                  }
                  </thead>
                  <tbody>
                    {
                      (!isLoading && dataset && dataset.length > 0) && (
                        dataset.map((item, index) =>
                          <tr key={index}>
                            <td className="align-middle">{item.id}</td>
                            <td className="align-middle">{item.nome_tamanho}</td>
                            <td className="align-middle text-center">
                              <Button theme='primary'
                                onClick={() => {select(item)}}
                                size="sm"
                                style={{ padding: '3px 8px', fontWeight: 'bold' }}>
                                { isSelected(item) ? LABELS.ACTIONS.BUTTONS.selected : LABELS.ACTIONS.BUTTONS.select }
                              </Button>
                            </td>
                          </tr>
                        )
                      )
                    }
                    {
                      (isLoading) && (
                        <tr>
                          <td className="align-middle text-center"
                            style={{padding: '20px', margin: '0px'}}
                            colSpan="10">
                            <i className="fa fa-spinner fa-spin text-dark"></i> { LABELS.LOADING_TEXT }
                          </td>
                        </tr>
                      )
                    }
                    {
                      (!dataset) && (
                        <tr>
                          <td className="align-middle text-center"
                            style={{padding: '20px', margin: '0px'}}
                            colSpan="10">
                            <i className="fa fa-times text-dark"></i> { LABELS.EMPTY_DATASET }
                          </td>
                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        {
          (!isLoading && dataset && dataset.length > 0) && (
            <ModalFooter {...modalFooter} style={{ padding: '10px'}}>
              <ButtonGroup>
                {
                  (pagination && pagination.length > 0) && (
                    pagination.map((value, index) =>
                      <Button
                        key={index} theme={value.current ? 'primary' : 'white'}
                        onClick={() => { getData(value.offset, value.limit) }}>
                        {value.index}
                      </Button>
                    )
                  )
                }
              </ButtonGroup>
            </ModalFooter>
          )
        }
      </Modal>
    </>
  );
}

export default ListWeights;
