/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useState, useRef, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Badge
} from "shards-react";

import { Form } from '@unform/web';
import { Input } from '../components/unform';
import * as Yup from 'yup';
import getValidationErrors from '../utils/getValidationErrors';
import Modal from "../components/modal";
import { useAuth } from '../hooks/Auth';

import logo from '../images/logo-novo-icon.png';

function Login({ history }){
  const formRef = useRef(null);

  const [ loginError, setLoginError ] = useState(false);
  const [ openModal, setOpenModal ] = useState(false);
  const { signIn, selectEnterprise, user } = useAuth();
  const [ isLoading, setIsLoading ] = useState(false);

  const handleSubmitLogin = useCallback(async (data) => {
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        cod_enterprise: Yup.string().required('O numero do ambiente é obrigatório'),
        login: Yup.string().required('O login é obrigatório'),
        password: Yup.string().required('A senha é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await login(data);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        formRef.current.setErrors(getValidationErrors(error));
        return;
      }
    }
  }, []);

  const login = useCallback(async (loginData) => {
    try {
      setIsLoading(true);
      const loginSuccess = await signIn(loginData);
      setLoginError(!loginSuccess);
      setIsLoading(false);

      if(loginSuccess){
        setOpenModal(true);
      }
    } catch (erro) {
      console.log(erro);
    }
  }, []);

  const modalToggle = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  const handleSelectEnterprise = useCallback(async (name, item) => {
    if (item) {
      try {
        await selectEnterprise(item.id);
        history.push('/relatorios/business-intelligence');
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setOpenModal(false);
    }
  }, [user]);

  return (
    <Container fluid className="main-content-container h-100 px-4">
      <Row noGutters className="h-100">
        <Col lg="3" md="5" className="auth-form mx-auto my-auto">
          <Card>
            <CardBody>
              {/* Logo */}
              <img
                className="auth-form__logo d-table mx-auto mb-1"
                src={logo}
                alt="Shards Dashboards - Login Template"
              />

              {/* Title */}
              <h5 className="auth-form__title text-center mb-4">
                <strong>NG Admin</strong>
                <br/>
                <Badge theme="danger" style={{padding: '3px', borderRadius: '3px'}}>beta</Badge>
              </h5>

              {/* Form Fields */}
              <Form ref={formRef} onSubmit={handleSubmitLogin}>
                <Input name="cod_enterprise" type="text" label="Ambiente" />
                <Input name="login" type="text" label="Login" />
                <Input name="password" type="password" label="Senha" />

                { loginError &&
                  <div className='text-center'>
                    <Badge theme="danger" className='mb-2'> Login/Senha digitados estão incorretos. </Badge>
                  </div>
                }
                { !isLoading ?
                    <Button
                      type='submit'
                      pill
                      theme="accent"
                      className="d-table mx-auto">
                      Entrar
                    </Button>
                  :
                    <Button
                      disabled
                      type='submit'
                      pill
                      theme="accent"
                      className="d-table mx-auto">
                      <i className="fa fa-spinner fa-spin text-dark"></i> &nbsp; Logando
                    </Button>
                }
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {openModal &&
        <Modal
          type='ListEnterprises'
          size="lg"
          open={openModal}
          toggle={modalToggle}
          selectCallback={handleSelectEnterprise} />
      }
    </Container>
  )
}

export default Login;
