import api from '../api';

class ProductsService extends api {

  getProducts (offset, limit, params) {
    let req_url = `/produtos/${offset}/${limit}`;

    if(params)
      req_url += this.generateQueryString(params);

    return this.api.get(req_url);
  }
}

export default ProductsService;
