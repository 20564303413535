import React, { Component } from 'react';
import { Container, Row, Col } from "shards-react";

import PageTitle from "./../components/common/PageTitle";
import SmallStats from "./../components/common/SmallStats";
import UsersOverview from "./../components/blog/UsersOverview";
import UsersByDevice from "./../components/blog/UsersByDevice";
import ListItems from "./../components/common/ListItems";
import RangeDatePicker from "../components/common/RangeDatePicker";
import ListProdutos from "../components/ecommerce/ListProdutos";

import { DashboardAPIService } from './../services/WebliniaERPAPI/index';

export default class BlogOverview extends Component {
  state = {
    smallStats: []
  }

  componentDidMount = async() => {
    await this.getVendasProduto();
    await this.getVendasFabricante();
    await this.getTotalFaturamento();
    await this.getTotalVendasDia();
    await this.getTicketMedio();
    await this.getNewClientesDiario();
    setInterval(this.init.bind(this), 10000);
  }

  init = async() => {
    await this.getVendasProduto();
    await this.getVendasFabricante();
    await this.getTotalFaturamento(true);
    await this.getTotalVendasDia(true);
    await this.getTicketMedio(true);
    await this.getNewClientesDiario(true);
  }

  numberFormat = (value) =>{
    return new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(value)
  }

  getVendasProduto = async() => {
    let api = new DashboardAPIService();
    try {
      let params = [
        { key: "ven->dta_venda[exp]", value: decodeURI("BETWEEN '2020-01-01 00:00:00;' AND '2020-02-29 23:59:59'")}
      ]

      let response = await api.getVendasProduto(274,params);
      let vendasProdutoQtd = [];
      let vendasProdutoRs = [];
      response['data'].forEach(item => {
          let img = require("../images/sales-overview/no-product-image.jpg");
          // if(item['img'] != "" && item['img'] != null)
          //   img = app_base_url +"/"+ item['img'].substring(item['img'].indexOf('assets'), item['img'].length);

          if(item['cod_produto'] != 623204){
            vendasProdutoQtd.push({title: item['nme_produto'], value: item['qtd_vendida'], image: img});
            vendasProdutoRs.push({title: item['nme_produto'], value: item['vlr_vendido'], image: img});
          }
      });

      vendasProdutoQtd.sort((a, b) => (a.value < b.value) ? 1 : -1);
      vendasProdutoRs.sort((a, b) => (a.value < b.value) ? 1 : -1)
      this.setState({vendasProdutoQtd: vendasProdutoQtd});
      this.setState({vendasProdutoRs: vendasProdutoRs});
    }
    catch(error) {
      console.log(error);
    }
  }

  getVendasFabricante = async() => {
    let api = new DashboardAPIService();
    try {
      let params = [
        { key: "id_empreendimento", value: '274'},
        { key: "fd", value: '2020-01-01'},
        { key: "ld", value: '2020-02-29'},
        { key: 'limit10', value: "false"}
      ]

      let response = await api.getVendasFabricate(params);
      let vendasFabricanteQtd = [];
      let vendasFabricanteRs = [];
      let namesFabricante = [];
      response['data'].forEach(item => {
          namesFabricante.push(item['nome_fabricante']);
          vendasFabricanteQtd.push({title: item['nome_fabricante'], value: item['qtd_total_vendas']});
          vendasFabricanteRs.push({title: item['nome_fabricante'], value: item['vlr_total_vendas']});
      });
      vendasFabricanteQtd.sort((a, b) => (a.value < b.value) ? 1 : -1);
      vendasFabricanteRs.sort((a, b) => (a.value < b.value) ? 1 : -1)

      this.setState({vendasFabricanteQtd: vendasFabricanteQtd});
      this.setState({vendasFabricanteRs: vendasFabricanteRs});

    }
    catch(error) {
      console.log(error);
    }
  }

  getTotalFaturamento = async(update=false) => {
    try {
      let api = new DashboardAPIService();
      let params = [
        { key: "id_empreendimento", value: 274},
        { key: "data_inicial", value: "2020-01-01"},
        { key: "data_final", value: "2020-02-29"},
      ];

      let response = await api.getTotalFaturamento(params);
      let vlrTotalFaturamento = 0;
      let dataSetFaturamento = [];
      response.data.forEach(mov => {
        //Total Faturamento
        vlrTotalFaturamento += mov.vlr_faturamento;
        dataSetFaturamento.push(mov.vlr_faturamento);
      });

      let smallStats = this.state.smallStats;

      if(update){
        smallStats[0].value = this.numberFormat(vlrTotalFaturamento);
        smallStats[0].chartLabels = Array.from(new Array(dataSetFaturamento.length), (_, i) => (i === 0 ? 1 : i));
        smallStats[0].chartLabels = Array.from(new Array(dataSetFaturamento.length), (_, i) => (i === 0 ? 1 : i));
        smallStats[0].datasets = [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgba(23,198,113,0.1)",
            borderColor: "rgb(23,198,113)",
            data: dataSetFaturamento
          }
        ];
      }else{
        smallStats.push({
          label: "Total Faturamento",
          value: this.numberFormat(vlrTotalFaturamento),
          percentage: null,
          increase: true,
          chartLabels: Array.from(new Array(dataSetFaturamento.length), (_, i) => (i === 0 ? 1 : i)),
          attrs: { md: "6", sm: "6" },
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: "rgba(23,198,113,0.1)",
              borderColor: "rgb(23,198,113)",
              data: dataSetFaturamento
            }
          ]
        });
      }

      this.setState({vlrTotalFaturamento: vlrTotalFaturamento});
      this.setState({ dataSetFaturamento: dataSetFaturamento });
      this.setState({smallStats: smallStats});
    } catch (error) {
      console.log(error)
    }
  }

  getTotalVendasDia = async(update=false) => {
    let api = new DashboardAPIService();
      let params = [
        { key: "id_empreendimento", value: 274},
      ];

      let response = await api.getTotalVendasDia("2020-01-01", "2020-02-29", params);
      let qtdVendas = 0;
      let dataSetVendas = [];
      response.data.forEach(vendas => {
        //Total de Vendas
        qtdVendas += vendas.total_vendas;
        dataSetVendas.push(vendas.total_vendas);
      });

      let smallStats = this.state.smallStats;

      if(update){
        smallStats[1].value = qtdVendas;
        smallStats[1].chartLabels = Array.from(new Array(dataSetVendas.length), (_, i) => (i === 0 ? 1 : i));
        smallStats[1].datasets = [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgb(25, 136, 255, 0.1)",
            borderColor: "rgb(25, 136, 255)",
            data: dataSetVendas
          }
        ];
      }else{
        smallStats.push({
          label: "Total Vendas",
          value: qtdVendas,
          percentage: null,
          increase: true,
          chartLabels: Array.from(new Array(dataSetVendas.length), (_, i) => (i === 0 ? 1 : i)),
          attrs: { md: "6", sm: "6" },
          datasets: [
            {
              label: "Today",
              fill: "start",
              borderWidth: 1.5,
              backgroundColor: "rgb(25, 136, 255, 0.1)",
              borderColor: "rgb(25, 136, 255)",
              data: dataSetVendas
            }
          ]
        });
      }

      this.setState({ qtdVendas: qtdVendas });
      this.setState({ dataSetVendas: dataSetVendas });
      this.setState({ smallStats: smallStats });
  }

  getTicketMedio = async(update=false) => {
    var dataSetVendas = this.state.dataSetVendas;
    var dataSetFaturamento = this.state.dataSetFaturamento;
    var vlrTotalFaturamento = this.state.vlrTotalFaturamento;
    var qtdVendas = this.state.qtdVendas;

    var dataSetTicket = [];
    var ticketPeriodo = vlrTotalFaturamento / qtdVendas;

    dataSetVendas.forEach((item, i) => {
      var ticket = dataSetFaturamento[i] / dataSetVendas[i];
      dataSetTicket.push(ticket);
    });

    let smallStats = this.state.smallStats;
    if(update){
      smallStats[2].value = this.numberFormat(ticketPeriodo);
      smallStats[2].chartLabels = Array.from(new Array(dataSetTicket.length), (_, i) => (i === 0 ? 1 : i));
      smallStats[2].datasets = [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgb(255, 180, 0,0.1)",
          borderColor: "rgb(255, 180, 0)",
          data: dataSetTicket
        }
      ]
    }else{
      smallStats.push({
        label: "Ticket Médio",
        value: this.numberFormat(ticketPeriodo),
        percentage: null,
        increase: true,
        chartLabels: Array.from(new Array(dataSetTicket.length), (_, i) => (i === 0 ? 1 : i)),
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgb(255, 180, 0,0.1)",
            borderColor: "rgb(255, 180, 0)",
            data: dataSetTicket
          }
        ]
      });
    }

      this.setState({dataSetTicket: dataSetTicket});
      this.setState({smallStats: smallStats});
  }

  getNewClientesDiario = async(update=false) => {
    var dataSetCliente = [];
    var totalClientes = 0;

    let api = new DashboardAPIService();
    let params = [
      { key: "tue->id_empreendimento", value: 274},
    ];

    let response = await api.getNewClientesDiario("2020-01-01", "2020-02-29", params);
    response.data.forEach(vendas => {
      //Total de Vendas
      totalClientes += parseInt(vendas.qtd_novos);
      dataSetCliente.push(parseInt(vendas.qtd_novos));
    });

    let smallStats = this.state.smallStats;
    if(update){
      smallStats[3].value = totalClientes;
      smallStats[3].chartLabels = Array.from(new Array(dataSetCliente.length), (_, i) => (i === 0 ? 1 : i));
      smallStats[3].datasets = [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgb(128, 0, 128,0.1)",
          borderColor: "rgb(128, 0, 128)",
          data: dataSetCliente
        }
      ]
    }else{
      smallStats.push({
        label: "Novos Clientes",
        value: totalClientes,
        percentage: null,
        increase: true,
        chartLabels: Array.from(new Array(dataSetCliente.length), (_, i) => (i === 0 ? 1 : i)),
        attrs: { md: "6", sm: "6" },
        datasets: [
          {
            label: "Today",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgb(128, 0, 128,0.1)",
            borderColor: "rgb(128, 0, 128)",
            data: dataSetCliente
          }
        ]
      });
    }

    this.setState({smallStats: smallStats});
  }

  render() {
    const { smallStats, vendasProdutoQtd, vendasProdutoRs, vendasFabricanteQtd, vendasFabricanteRs, chartData, dateSetTopFabricante } = this.state;
    return (
      <Container fluid className="main-content-container px-4">
        <Row noGutters className="page-header py-4">
          {/* Page Header :: Title */}
          <PageTitle title="Dashboard" subtitle="Visão Analítica" className="text-sm-left mb-6" />

          {/* Page Header :: Actions */}
          <Col xs="12" sm="4" className="col d-flex align-items-center">

          </Col>

          {/* Page Header :: Datepicker */}
          <Col sm="4" className="d-flex">
            {/* <RangeDatePicker className="justify-content-end" /> */}
          </Col>
        </Row>

        <Row>
          {smallStats.map((stats, idx) => (
            <Col key={idx} md="6" lg="3" className="mb-4">
              <SmallStats
                id={`small-stats-${idx}`}
                chartData={stats.datasets}
                chartLabels={stats.chartLabels}
                label={stats.label}
                value={stats.value}
                percentage={stats.percentage}
                increase={stats.increase}
                decrease={stats.decrease}
                usePer={stats.usePer}
              />
            </Col>
          ))}
        </Row>

        <Row>
          {/* Users Overview */}
          <Col lg="8" md="12" sm="12" className="mb-4">
            <UsersOverview
              title="Vendas Hora a Hora"
              chartData={chartData}
            />
          </Col>

          {/* Users by Device */}
          <Col lg="4" md="6" sm="12" className="mb-4">
            <UsersByDevice
              title=" Top 10 Vendas por Marca"
            />
          </Col>
        </Row>

        <Row>
          {/* Vendas Por Produto (Qtd) */}
          <Col lg="6" md="12" sm="12" className="mb-4">
            <ListProdutos
              title="Vendas Por Produto (Qtd)"
              categories={vendasProdutoQtd}
            />
          </Col>

          {/* Vendas Por Produto (R$) */}
          <Col lg="6" md="12" sm="12" className="mb-4">
            <ListProdutos
              title="Vendas Por Produto (R$)"
              categories={vendasProdutoRs}
              typeValue="money"
            />
          </Col>

          {/* Vendas Por Marca (Qtd) */}
          <Col lg="6" md="12" sm="12" className="mb-4">
            <ListItems
              title="Vendas Por Marca (Qtd)"
              referralData={vendasFabricanteQtd}
            />
          </Col>

          {/* Vendas Por Produto (R$) */}
          <Col lg="6" md="12" sm="12" className="mb-4">
            <ListItems
              title="Vendas Por Marca (R$)"
              typeValue="money"
              referralData={vendasFabricanteRs}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
