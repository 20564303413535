import React, { useState, useCallback } from 'react';
import PropTypes from "prop-types";

import { InputGroup, InputGroupAddon, FormInput, Button } from "shards-react";

function InputSearch({ submit, onChange , ...rest }) {
  const handleSubmit = (e) => {
    if(e)
      e.preventDefault();

    submit()
  };

  const handleOnChangeSearch = useCallback((e) => {
    const value = e.target.value
    onChange(value);
  });

  return (
    <>
      <form onSubmit={handleSubmit}>
        <InputGroup seamless className="mb-12">
          <FormInput name='search' onChange={handleOnChangeSearch}/>
          <InputGroupAddon type="append">
            <Button type='button' theme='primary' size="sm" onClick={handleSubmit}>
              Buscar
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </form>
    </>
  );
}

InputSearch.propTypes = {
  /**
   * The class name.
   */
  className: PropTypes.string,
  /**
   * The children nodes.
   */
  children: PropTypes.node,
  /**
   * Whether it is inline, or not.
   */
  inline: PropTypes.bool,
  /**
   * The input type.
   */
  type: PropTypes.string,
  // type: PropTypes.oneOf(INPUT_TYPES),
  /**
   * Whether it is plaintext, or not.
   */
  plaintext: PropTypes.bool,
  /**
   * The input's size.
   */
  size: PropTypes.string,
  /**
   * Whether it is valid, or not.
   */
  valid: PropTypes.bool,
  /**
   * Whether it is invalid, or not.
   */
  invalid: PropTypes.bool,
  /**
   * The inner ref.
   */
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string
  ])
};

export default InputSearch;
