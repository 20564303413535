import axios from 'axios';

import { KEYS } from '../Storage/storage.service';


export const app_bases_url = {
  '999': "http://webliniaapi/",
  '998': "http://localhost/webliniaerp-api/",
  '001': "https://api.clientes.ngweb.net.br/",
  '002': "https://api.crn.ngweb.net.br/",
  '020': "http://ngclientes.srvngtst1.virtuaserver.com.br/api/",
}

export default class WebliniaERPAPI {
  constructor(cod_enterprise) {
    if(!cod_enterprise)
      cod_enterprise = localStorage.getItem(KEYS.USER_LOGGED_COD_ENTERPRISE);

    const baseURL = app_bases_url[cod_enterprise];
    if(baseURL){
      this.api = axios.create({
        baseURL
      });

      this.api.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';

      // Set the AUTH token for any request
      this.api.interceptors.request.use(function (config) {
        // config.headers.genericKey = "someGenericValue";
        if(config.url != '/user/auth') {

          let JsonUser = localStorage.getItem(KEYS.USER_LOGGED);
          if(JsonUser != null){
            let user = JSON.parse(JsonUser)

            if(user){
              config.headers.common['Authorization'] = 'Bearer ' + user.access_token;
              config.headers.common['x-id-usuario'] = user.id;
            }
          } else {
            // delete config.headers.common['Authorization'];
            // delete config.headers.common['x-id-usuario'];
          }
        }

        return config;
      });

    } else {
      console.warn('codigo do empreendimento errado');
    }

  }

  mountArrayOfColumns = (params) => {
    let columns = '';
    params.forEach((param, key) => {
      if ((key+1) === params.length)
        columns += param.dataBaseColumn + ' AS ' + param.accessor
      else
        columns += param.dataBaseColumn + ' AS ' + param.accessor + ','
    });
    return columns;
  }

  generateQueryString(params) {
    var request_url = "";
    params.forEach((param, key) => {
      if ((param.key) === 'columns'){
        let columns = this.mountArrayOfColumns(param.value);
        if (key === 0)
          request_url += '?' + param.key + '=' + columns;
        else
          request_url += '&' + param.key + '=' + columns;
      }else {
        if (key === 0)
          request_url += '?' + param.key + '=' + param.value;
        else
          request_url += '&' + param.key + '=' + param.value;
      }
    });
    return request_url;
  }
}
