import api from '../api';

class UsersService extends api {

  Login = ({ login, password, }) => {
    let postData = new FormData();
    postData.append('login', login);
    postData.append('senha', password);

    return this.api.post('/user/auth', postData);
  }

  getEnterpriseUser = (enterprise_id, user_id) => {
    let req_url = '/usuario/' + enterprise_id + '/' + user_id;

    return this.api.get(req_url);
  }


  getCustomers (offset, limit, params) {
    let req_url = `/usuarios/${offset}/${limit}`;

    if(params)
      req_url += this.generateQueryString(params);

    return this.api.get(req_url);
  }
}

export default UsersService;
