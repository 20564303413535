import api from '../api';

class BIAPIService extends api {
  getDataView (params) {
    let req_url = `/bi`;

    if(params)
      req_url += this.generateQueryString(params);

    return this.api.get(req_url);
  }

  getViews (params) {
    let req_url = `/bi/views`

    if(params)
      req_url += this.generateQueryString(params);

    return this.api.get(req_url);
  }

  getAdvancedFilter (id_view, params) {
    let req_url = `/bi/view/${id_view}/filters`

    if(params)
      req_url += this.generateQueryString(params);

    return this.api.get(req_url);
  }
}

export default BIAPIService;
