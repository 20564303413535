import api from '../api';

class EmpreendimentoService extends api {
  getEmprendimentos(offset, limit, params) {
    let req_url = '/empreendimentos/'+offset+'/'+limit;

    if(params)
      req_url += this.generateQueryString(params);

    return this.api.get(req_url);
  }
}

export default EmpreendimentoService;
