import React, { useCallback, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { sortBy } from 'underscore';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { TableExport } from 'tableexport';
import getValidationErrors from '../utils/getValidationErrors';
import { useAuth } from '../hooks/Auth';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardHeader,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "shards-react";

import { Input, Select, InputAdvancedFilter } from '../components/unform';
import PageTitle from "../components/common/PageTitle";
import PivotTable from '../components/pivot-table';
import Modal from '../components/modal';

import { BIService } from '../services/WebliniaERPAPI';
import { Local, KEYS } from '../services/Storage/storage.service';

const BIView = () => {
  const { user, enterprise } = useAuth();
  const formRef = useRef(null);
  const [views, setViews] = useState([]);
  const [selectView, setSelectView] = useState(null);
  const [selectViewName, setSelectViewName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pivotData, setPivotData] = useState([]);
  const [pivotTemplate, setPivotTemplate] = useState([]);
  const [advancedFiltersView, setAdvancedFiltersView] = useState([]);
  const [modalsOpen, setModalsOpen] = React.useState({});
  const [advancedFilters, setAdvancedFilters] = useState();
  const [buttonsExport, setButtonsExport] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [tableExport, setTableExport] = useState(null);

  const loadViews = useCallback(async () => {
    const biService = new BIService();
    const response = await biService.getViews();
    setViews(response.data);
  });

  const handleSubmitFilter = useCallback(async(data) => {
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        view: Yup.string().required('Selecione uma Visão'),
        startDate: Yup.string().required('Selecione uma Data Inicial'),
        endDate: Yup.string().test("is-greater", "Deve ser maior que a inicial", function(value) {
          const { startDate } = this.parent;
          return moment(value, "yyyy-mm-dd").isSameOrAfter(moment(startDate, "yyyy-mm-dd"));
        }).required('Selecione uma Data Final')
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await getDataView(data);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        formRef.current.setErrors(getValidationErrors(error));
        return;
      }
    }
  }, [advancedFilters]);

  const getAdvancedFilter = useCallback(async (id_view) => {
    const biService = new BIService();
    if(id_view != ''){
      try{
        const response = await biService.getAdvancedFilter(id_view);
        setAdvancedFiltersView(response.data);
      } catch(error){
        setAdvancedFiltersView([]);
      }
    } else {
      setAdvancedFiltersView([]);
    }
  }, [])

  const modalToggle = useCallback((name) => {
    setModalsOpen({
      ...modalsOpen,
      [name]: !modalsOpen[name]
    });

  }, [modalsOpen]);

  const handleSelect = useCallback((name, value) => {
    setAdvancedFilters({
      ...advancedFilters,
      [name]: value
    });
  }, [advancedFilters]);

  const getValueAdvancedFilter = useCallback((property, name) => {
    if(advancedFilters){
      if(advancedFilters[property]){
        return advancedFilters[property][name];
      }
      else {
        return '';
      }
    }
    return '';
  }, [advancedFilters]);

  const getDataView = useCallback(async (params) => {
    setPivotData([]);
    setIsError(false);
    setIsLoading(true);
    const biService = new BIService();

    const urlParams = [
      { key: 'id_view', value: params.view },
      { key: 'id_empreendimento', value: Local.getData(KEYS.USER_LOGGED_ENTERPRISE).id_empreendimento },
      { key: "data[exp]", value: decodeURI(`BETWEEN '${params.startDate} 00:00:00' AND '${params.endDate} 23:59:59'`)}
    ];

    if(advancedFilters){
      Object.keys(advancedFilters).map((key) => {
        advancedFiltersView.map((filter) => {
          const item = advancedFilters[key];
          if(key == filter.type){
            const param = {
              key: filter.colum_bd,
              value: item[filter.colum_value]
            }

            urlParams.push(param);
          }
        });
      });
    };

    try {
      const response = await biService.getDataView(urlParams);
      const template = formatTemplatePivotTable(response.data.template);
      const data = formatDataPivotTable(response.data.data, template.colunas);

      setPivotTemplate(template);
      setPivotData(data);
      setIsLoading(false);
      testHandleExport();
    } catch (error) {
      console.warn(error)
      setIsLoading(false);
      setIsError(true);
    }
  }, [advancedFilters]);

  const formatDataPivotTable = useCallback((data, template) => {
    const formatData = [];

    data.map((item) => {
      const line = {};
      if(template){
        template.map((colum) => {
          if(colum.format == 'date')
            line[colum.nome] = moment(item[colum.coluna]).format('DD/MM/YYYY');
          else if(colum.format == 'number')
            line[colum.nome] = (item[colum.coluna]) ? item[colum.coluna].toFixed(2) : 0;
          else
            line[colum.nome] = item[colum.coluna];
        });

        formatData.push(line);
      } else {
        formatData.push(item);
      }
    });

    return formatData;
  }, []);

  const formatTemplatePivotTable = useCallback((template) => {
    if(template){
      template.cols = [];
      template.rows = [];

      template.colunas = sortBy(template.colunas, 'ordination');

      template.colunas.map((coluna) => {
        if(coluna.type === 'col')
          template.cols.push(coluna.nome)
        else if (coluna.type === 'row')
          template.rows.push(coluna.nome)
      });
    }

    return template;
  });

  const handleResetFilter = useCallback(() => {
    formRef.current.reset();
    setAdvancedFiltersView([]);
    setAdvancedFilters({});
  }, []);

  const dropdownToggle = useCallback(() => {
    setDropdownOpen(!dropdownOpen);
  }, [dropdownOpen]);

  const handleExportTable = useCallback(() => {
    const view = views.filter(view => view.id == selectView);
    if(view.length > 0){
      const nameView = view[0].nome;

      var table = new TableExport(document.getElementsByClassName("pvtTable"), {
        formats: ['xlsx', 'csv', 'txt'],
        filename: nameView,
        sheetname: nameView,
        trimWhitespace: false,
        exportButtons: false,
      });

      setTableExport(table);

      const htmlTable = document.getElementsByClassName('pvtTable');

      if(htmlTable.length > 0){
        const tableId = htmlTable[0].getAttribute('tableexport-key');

        const exportData = table.getExportData()[tableId];
        console.log(exportData);

        const data = [];
        Object.keys(exportData).map(item => {
          data.push({ ...exportData[item], type: item });
        });

        setButtonsExport(data);
      }
    }
  }, [selectView]);

  const exportTable = useCallback((item) => {

    const { data, mimeType, filename, fileExtension, merges, RTL, sheetname } = item;
    if(tableExport)
      tableExport.export2file(data, mimeType, filename, fileExtension, merges, RTL, sheetname);

  }, [tableExport]);

  const testHandleExport = useCallback(() => {
    const htmlTable = document.getElementsByClassName('pvtTable');
    console.log(selectView);
    if(htmlTable.length > 0){
      htmlTable[0].setAttribute("id", "pivotTableExcel");
    }
  }, [])

  useEffect(() => {
    getAdvancedFilter(selectView);
    const view = views.filter(view => view.id == selectView);
    if(view.length > 0){
      setSelectViewName(view[0].nome);
    }
  }, [selectView])

  useEffect(() => {
    loadViews();
  }, []);

  const onChangePivot = (data) => {
    console.log(data);
    setTimeout(() => {
      testHandleExport();
    }, 100)
  };
  return (
    <>
      <Container fluid className='main-content-container px-4 pb-4'>
        <Row noGutters className='page-header py-4'>
          <PageTitle title='Business Intelligence' subtitle='Relatórios' className='text-sm-left mb-3' />
        </Row>

        <Card className='p-0'>
          <CardHeader className='p-0'>
            <Container fluid className='file-manager__filters border-bottom'>
              <Form ref={formRef} onSubmit={handleSubmitFilter}>
                <Row>
                  <Col sm='2'>
                    <Select
                      name='view'
                      label='Visão'
                      data={views}
                      onChange={(e) => {setSelectView(e.target.value)}}
                    />
                  </Col>
                  <Col sm="2">
                    <Input
                      type='date'
                      name='startDate'
                      label='Data Inicial'
                    />
                  </Col>
                  <Col sm='2'>
                    <Input
                      type='date'
                      name='endDate'
                      label='Data Final'
                    />
                  </Col>

                  <Col className='d-flex ml-auto my-auto justify-content-end'>
                    {pivotData.length > 0 && (
                      <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="btn btn-white mr-2"
                        table="pivotTableExcel"
                        filename={selectViewName}
                        sheet={selectViewName}
                        buttonText="Exportar"/>
                      // <Dropdown
                      //   open={dropdownOpen}
                      //   toggle={dropdownToggle}>
                      //   <DropdownToggle caret theme='white'
                      //     style={{marginRight: '10px', color: 'black'}}
                      //     onClick={handleExportTable}>
                      //       <i className='fa fa-file text-dark'></i> Exportar &nbsp;
                      //   </DropdownToggle>
                      //   <DropdownMenu small>
                      //     {buttonsExport.map(item => {
                      //       return (
                      //         <DropdownItem type='button' onClick={() => {exportTable(item)}}>{item.type}</DropdownItem>
                      //       )
                      //     })}
                      //   </DropdownMenu>
                      // </Dropdown>
                    )
                    }
                    <Button
                      type='button'
                      theme='white'
                      style={{marginRight: '10px', color: 'black'}}
                      onClick={handleResetFilter}>
                      <i className='fa fa-times'></i> Limpar Filtro
                    </Button>

                    <Button type='submit' theme='primary'>
                      <i className='fa fa-filter'></i> Aplicar Filtro
                    </Button>
                  </Col>
                </Row>
                <Row>
                  {advancedFiltersView.map((item) => {
                    return (
                      <Col key={item.id} sm='2'>
                        <InputAdvancedFilter
                          label={item.label}
                          onClick={() => {modalToggle(item.type)}}
                          value={getValueAdvancedFilter(item.type, item.colum_name)}
                        />
                      </Col>
                    )
                  })}
                </Row>
              </Form>
            </Container>
          </CardHeader>
            {pivotData.length > 0 &&
              <CardBody className='p-2'>
                <PivotTable
                  data={pivotData}
                  rows={pivotTemplate.rows}
                  cols={pivotTemplate.cols}
                  aggregatorName={(pivotTemplate.aggregator_name) ? pivotTemplate.aggregator_name : 'Contagem'}
                  vals={[pivotTemplate.aggregator_val]}
                  rendererName={pivotTemplate.renderer_name}
                  onChangePivot={onChangePivot}
                />
              </CardBody>
            }
            {isLoading &&
              <CardBody className='p-10 text-center'>
                <i className="fa fa-spinner fa-spin text-dark"></i> Carregando Registros
              </CardBody>
            }
            {isError &&
              <CardBody className='p-10 text-center'>
                <i className="fa fa-times text-dark"></i> Nenhum Registro Encontrado.
              </CardBody>
            }
        </Card>
      </Container>

      {advancedFiltersView.map((item) => {
        if(modalsOpen[item.type]){
          return (
            <Modal
              key={item.id}
              type={item.type}
              size="lg"
              open={modalsOpen[item.type]}
              toggle={() => {modalToggle(item.type)}}
              selectCallback={handleSelect} />
          )
        } else {
          return <></>
        }
      })}
    </>
  )
}

export default BIView;
