import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import PropTypes from "prop-types";
import classNames from "classnames";

import { FormGroup, FormFeedback, FormInput } from "shards-react";

function Input({ name, ...rest }) {
  const inputRef = useRef(null);

  const {
    fieldName,
    registerField,
    defaultValue,
    error
  } = useField(name);

  const {
    className,
    plaintext,
    size,
    invalid,
    label
  } = {...rest};

  const classes = classNames(
    className,
    plaintext ? "form-control-plaintext" : "form-control",
    plaintext && "w-100",
    size && `form-control-${size}`,
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })
  }, [fieldName, registerField]);

  return (
    <>
      <FormGroup>
        {label && (
          <label htmlFor={fieldName}>{label}</label>
        )}
        <FormInput
          id={fieldName}
          innerRef={inputRef}
          className={classes}
          defaultValue={defaultValue}
          invalid={invalid || !!error}
          {...rest}
        />
        {error && (
          <FormFeedback>{error}</FormFeedback>
        )}
      </FormGroup>
    </>
  );
}

Input.propTypes = {
  /**
   * The class name.
   */
  className: PropTypes.string,
  /**
   * The children nodes.
   */
  children: PropTypes.node,
  /**
   * Whether it is inline, or not.
   */
  inline: PropTypes.bool,
  /**
   * The input type.
   */
  type: PropTypes.string,
  // type: PropTypes.oneOf(INPUT_TYPES),
  /**
   * Whether it is plaintext, or not.
   */
  plaintext: PropTypes.bool,
  /**
   * The input's size.
   */
  size: PropTypes.string,
  /**
   * Whether it is valid, or not.
   */
  valid: PropTypes.bool,
  /**
   * Whether it is invalid, or not.
   */
  invalid: PropTypes.bool,
  /**
   * The inner ref.
   */
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string
  ])
};

export default Input;
