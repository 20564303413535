export const LAYOUT_TYPES = {
  DEFAULT: 'DEFAULT',
  HEADER_NAVIGATION: 'HEADER_NAVIGATION',
  ICON_SIDEBAR: 'ICON_SIDEBAR'
}

export const FIELDS = {
  PRODUCT: {
    ID: {
      label: 'ID'
    },
    NAME: {
      input_id: 'product_id',
      label: 'Nome do Produto',
      placeholder: 'Insira o nome do produto'
    },
    FLAVOR: 'Cor/Sabor',
  },
  ENTERPRISE: {
    NAME: {
      label: 'Nome do Empreendimento'
    }
  },
  CAMPAIGN: {
    NAME: {
      label: 'Nome da Campanha'
    }
  },
  CATEGORY: {
    ID: {
      label: 'ID'
    },
    NAME: {
      label: 'Categoria'
    },
  },
  MANUFACTURER: {
    ID: {
      label: 'ID'
    },
    NAME: {
      label: 'Fabricante'
    },
  },
  CUSTOMER: {
    ID: {
      label: 'ID'
    },
    NAME: {
      label: 'Nome'
    },
  },
  FLAVORS: {
    ID: {
      label: 'ID'
    },
    NAME: {
      label: 'Sabor'
    },
  },
  COLORS: {
    ID: {
      label: 'ID'
    },
    NAME: {
      label: 'Cor'
    },
  },
  WEIGHTS: {
    ID: {
      label: 'ID'
    },
    NAME: {
      label: 'Tamanho'
    },
  }
}

export const LABELS = {
  LOADING_TEXT: "Aguarde, carregando...",
  EMPTY_DATASET: "Nenhum registro encontrado!",
  ACTIONS: {
    label: 'Ações',
    BUTTONS: {
      select: 'Selecionar',
      selected: 'Selecionado'
    }
  }
}
