import React from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  FormSelect,
  Card,
  CardHeader,
  CardBody,
  CardFooter
} from "shards-react";

import Chart from "../../utils/chart";

import { DashboardAPIService } from './../../services/WebliniaERPAPI/index';

class UsersByDevice extends React.Component {
  constructor(props) {
    super(props);

    this.canvasRef = React.createRef();
  }

  componentDidMount = async() => {
    await this.getTopFabricante();
    setInterval(this.init.bind(this), 30000);

  }

  init = async() => {
    await this.getTopFabricante();
  }

  getTopFabricante = async() => {
    let api = new DashboardAPIService();
    try {
      let params = [
        { key: "id_empreendimento", value: '274'},
        { key: "fd", value: '2019-11-01'},
        { key: "ld", value: '2019-11-30'},
        { key: 'limit10', value: "false"}
      ]

      let response = await api.getVendasFabricate(params);
      let vendasFabricanteQtd = [];
      let vendasFabricanteRs = [];
      let namesFabricante = [];
      response['data'].forEach(item => {
          namesFabricante.push(item['nome_fabricante']);
          vendasFabricanteQtd.push({title: item['nome_fabricante'], value: item['qtd_total_vendas']});
          vendasFabricanteRs.push({title: item['nome_fabricante'], value: item['vlr_total_vendas']});
      });
      vendasFabricanteQtd.sort((a, b) => (a.value < b.value) ? 1 : -1);
      vendasFabricanteRs.sort((a, b) => (a.value < b.value) ? 1 : -1)

      var dateSetTopFabricante = [];
      var labelsFabricante = [];
      for (let index = 0; index < 10; index++) {
        labelsFabricante.push(namesFabricante[index]);
        dateSetTopFabricante.push(vendasFabricanteQtd[index]['value']);
      }
      this.setState({vendasFabricanteQtd: vendasFabricanteQtd});
      this.setState({vendasFabricanteRs: vendasFabricanteRs});
      this.setState({dateSetTopFabricante: dateSetTopFabricante});
    }
    catch(error) {
      console.log(error);
    }

    var chartConfig = {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false
     }
    };

    var chartData = {
      datasets: [
        {
          hoverBorderColor: "#ffffff",
          data: dateSetTopFabricante,
          backgroundColor: [
            "rgba(0,123,255,1)",
            "rgba(0,123,255,0.9)",
            "rgba(0,123,255,0.8)",
            "rgba(0,123,255,0.7)",
            "rgba(0,123,255,0.6)",
            "rgba(0,123,255,0.5)",
            "rgba(0,123,255,0.4)",
            "rgba(0,123,255,0.3)",
            "rgba(0,123,255,0.2)",
            "rgba(0,123,255,0.1)",
          ]
        }
      ],
      labels: labelsFabricante
    };

    if(this.chart != null)
      this.chart.destroy();

    this.chart = new Chart(this.canvasRef.current, {
      title: "Top 10 Vendas Por Fabricante",
      type: "pie",
      data: chartData,
      options: chartConfig
    });

    this.chart.render();
  }


  render() {
    const { title } = this.props;
    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{title}</h6>
        </CardHeader>
        <CardBody className="d-flex py-0">
          <canvas
            height="220"
            ref={this.canvasRef}
            className="blog-users-by-device m-auto"
          />
        </CardBody>
        <CardFooter className="border-top">
          <Row>
            {/* <Col>
              <FormSelect
                size="sm"
                value="last-week"
                style={{ maxWidth: "130px" }}
                onChange={() => {}}
              >
                <option value="last-week">Last Week</option>
                <option value="today">Today</option>
                <option value="last-month">Last Month</option>
                <option value="last-year">Last Year</option>
              </FormSelect>
            </Col>
            <Col className="text-right view-report"> */}
              {/* eslint-disable-next-line */}
              {/* <a href="#">View full report &rarr;</a>
            </Col> */}
          </Row>
        </CardFooter>
      </Card>
    );
  }
}

UsersByDevice.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The chart config object.
   */
  chartConfig: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object,
  /**
   * The chart data.
   */
  chartData: PropTypes.object
};

UsersByDevice.defaultProps = {
  title: "Users by device",
  chartData: {
    datasets: [
      {
        hoverBorderColor: "#ffffff",
        data: [68.3, 24.2, 7.5],
        backgroundColor: [
          "rgba(0,123,255,0.9)",
          "rgba(0,123,255,0.5)",
          "rgba(0,123,255,0.3)"
        ]
      }
    ],
    labels: ["Desktop", "Tablet", "Mobile"]
  }
};

export default UsersByDevice;
