import CampaignModal from './campaign.modal';
import ListCategory from './ListCategory';
import ListManufacturer from './ListManufacturer';
import ListProduct from './ListProduct';
import ListCustomers from './ListCustomers';
import ListVendors from './ListVendors';
import ListFlavors from './ListFlavors';
import ListColors from './ListColors';
import ListWeights from './ListWeights';
import ListEnterprises from './ListEnterprises';

export default {
  CampaignModal,
  ListCategory,
  ListManufacturer,
  ListProduct,
  ListCustomers,
  ListVendors,
  ListFlavors,
  ListColors,
  ListWeights,
  ListEnterprises
}
